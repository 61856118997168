import React from 'react';

// Material
import { Close } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router-dom";

// Bilder
// import bild1 from "../css/image/restoran3.jpg";

import Icon from '../components/icon';


class Kontakt extends React.Component {

    constructor(props) {
        super(props)
        this.state = {};
        this.values = {}
    }
    componentDidMount() {
        window.scroll(0, 0);
    }

    //################################################## errorMessage
    handleErrorMessages(open, message, stack, erroClass, dauer) {
        erroClass = erroClass ? erroClass : "erro";
        dauer = dauer ? dauer : 10000;
        if (open) {
            if (message === "Unexpected end of JSON input") { message = "keine Verbindung zum Server" }
            this.setState({
                errorOpen: true,
                erroMessage: message,
                erroStack: stack,
                erroClass: erroClass,
                dauer: dauer
            });
        } else {
            this.setState({ errorOpen: false });
        }
    };
    //################################################## errorMessage

    setValue(e) {
        this.values[e.target.name] = e.target.value;
    }

    sentMail(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("mail", this.values.mail);
        formData.append("titel", this.values.titel);
        formData.append("beschreibung", this.values.beschreibung);
        fetch('https://pireass.de/sendMail.php', {
            method: "POST",
            body: formData
        })
            .then((response) => {
                return response.json()
            })
            .then(() => {
                this.handleErrorMessages(true, "E-Mail wurde erfolgreich gesendet.", "Wir bedanken uns für Ihre Nachricht. Sie werden bald eine Rückmeldung erhalten.", "info")
                this.form.reset();
            })
            .catch(() => {
                this.handleErrorMessages(true, "E-Mail könnte nicht gesendet werden", "Überprüfen Sie Ihre Internetverbindung oder rufen Sie uns an, um persönlich einen Kontakt herzustellen.", "erro")
            })
    }


    render() {
        return (<div className="text-secondary hertDiv pl-0 pr-0 pb-0 animate__animated animate__delay animate__fadeIn" style={{ marginBottom: "150px" }}>

            <div className="ff-4 container bg-white pb-" style={{ paddingTop: "150px" }}>

                <h3 className="border-bottom p-2">Kontakt</h3>


                <div className="row m-0">

                    <div className="col-md-6 mt-5 d-flex flex-column _mobile-text ">
                        <h5 className="my-5">Sie haben Fragen? Dann nutzen Sie unser Kontaktformular.</h5>
                        <div className="">
                            <p className="m-0"> Phone: 05262 9943483</p>
                            <p className="m-0"> E-Mail: niko.zekelidis@outlook.com</p>
                            <p className="m-0"> Adresse: Hauptstraße 27, 32699 Extertal</p>
                        </div>
                        <div className="my-5"></div>
                        <div className="">
                            <h4 className="m-0">Öffnungszeiten</h4>
                            <p className="m-0"> DO - SA 17:00 - 21:00 Uhr</p>
                            <p className="m-0"> SO: 12:00 - 14:00 Uhr 17:00 - 21:00 Uhr</p>
                            <p className="m-0"> <b>MONTAG DIENSTAG MITTWOCH</b> <i>RUHETAG</i></p>
                        </div>
                    </div>


                    <div className="col-md-6 mt-5 text-center">
                        <Icon icon="AiOutlineMail" style={{ color: "#f50057", fontSize: "55pt" }} />
                        <form onSubmit={this.sentMail.bind(this)} ref={(el) => this.form = el} className="row m-0">
                            <input name={"mail"} onChange={(e) => this.setValue(e)} required type="email" placeholder={"E-Mail"} className="col-12 kontaktInput" />
                            <input name={"titel"} onChange={(e) => this.setValue(e)} required type="text" placeholder={"Titel"} className="col-12 mt-2 kontaktInput" />
                            <textarea name={"beschreibung"} onChange={(e) => this.setValue(e)} required type="text" placeholder={"Text"} style={{ minHeight: "200px" }} className="col-12 mt-2 kontaktInput" />
                            <button type="submit" className="mt-3 col-12 sendenButton">{"Senden"} <Icon icon="Send" style={{ color: "inherit" }} /></button>
                        </form>
                    </div>
                </div>


                {/*   <div className="">
                    <img className="kontaktBild" alt={"index"} src={bild1} />
                </div> */}

                <div className={this.state.erroClass}>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.state.errorOpen}
                        autoHideDuration={this.state.dauer}
                        onClose={() => { this.handleErrorMessages(false) }}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<div><p id="message-id">{this.state.erroMessage}</p> <span>{this.state.erroStack}</span></div>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={() => { this.handleErrorMessages(false) }}
                            >
                                <Close />
                            </IconButton>,
                        ]}
                    />

                </div>
            </div>
        </div>
        );
    }
}

export default withRouter(Kontakt);


